import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { ReactNode, useCallback } from 'react';
import type { Address } from 'viem';

import MediaStack, { mapToLaunchMediaAsset } from 'components/MediaStack';
import MediaStackHorizontal from 'components/MediaStackHorizontal';
import MomentDynamicLabel from 'components/MomentDynamicLabel';
import { Contributors } from 'components/MomentPageHeader';
import { MomentTag } from 'components/MomentTag';
import ShowcaseMedia from 'components/ShowcaseMedia';
import { ArtworkUnsupportedMessageWrapper } from 'components/artworks/media/ArtworkUnsupportedMessageWrapper';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import { H2Heading } from 'components/base/Heading';
import Link from 'components/base/Link';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import { MomentStats } from 'components/moments/MomentStats';
import UserTag from 'components/users/UserTag';
import WorldTag from 'components/worlds/WorldTag';
import useAnalytics from 'contexts/analytics/useAnalytics';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import AssetUnsupportedIcon from 'assets/icons/asset-unsupported-icon.svg';
import { ApiMomentFragment } from 'gql/api/api-fragments.generated';
import { useMomentBookmarks } from 'gql/api/mutations/moment-bookmarks.generated';
import { useMomentCreators } from 'gql/api/queries/moment-creators.generated';
import { useMomentStats } from 'gql/api/queries/moment-stats.generated';
import { useWorldSlugById } from 'gql/api/queries/world-slug-by-id.generated';
import useModal from 'hooks/use-modal';
import { ChainId } from 'lib/chains';
import { getNoAssetArtworkLabel } from 'utils/artwork/artwork';
import { optimizeAsset } from 'utils/imgix';
import {
  mapApiMediaToLaunchMedia,
  mapShowcaseMediaToPreviewMedia,
} from 'utils/media';
import { getMomentStatus } from 'utils/moment';
import { getPath } from 'utils/router';
import { createCanvasBackground } from 'utils/styles';

import { UserLight, UserMicro } from 'types/Account';
import { Drop } from 'types/Drop';
import { EditionWithLegacyAsset } from 'types/Edition';
import {
  LimitedEditionCollectionSale,
  TimedEditionCollectionSale,
} from 'types/EditionSale';
import { SellableItemType } from 'types/Marketplace';
import { MomentStatsType } from 'types/Moment';
import { MarketNft } from 'types/Nft';
import { WorldOverview } from 'types/World';
import { LaunchMediaAsset, OptimizeImageOptions } from 'types/media';

import ShowcaseBadge from './ShowcaseBadge';
import ShowcaseContainer from './ShowcaseContainer';
import ShowcaseDescription from './ShowcaseDescription';
import ShowcaseFrame from './ShowcaseFrame';
import ShowcaseMarket from './ShowcaseMarket';

const SHOWCASE_MEDIA_OPTIONS: OptimizeImageOptions = {
  dpr: 2,
  q: 70,
  h: 640,
  w: 640,
};

export type ShowcaseEditionType = Omit<
  EditionWithLegacyAsset,
  'contractType'
> & {
  contractAddress: Address;
  description: string | null;
  totalSales: number;
  chainId: ChainId;
};

/**
 * TODO (highlight-buy): remove everything from this type
 * @deprecated migrate to use Drop['sale'] instead
 */
type ShowcaseDropDeprecatedFields = Pick<
  Drop,
  | 'clearingPrice'
  | 'earlyAccessStartTime'
  | 'generalAvailabilityStartTime'
  | 'mintPrice'
  | 'saleType'
  | 'status'
>;

type ShowcaseDropType = ShowcaseDropDeprecatedFields &
  Pick<
    Drop,
    | 'chainId'
    | 'contractAddress'
    | 'creator'
    | 'media'
    | 'name'
    | 'nftCount'
    | 'slug'
  > & {
    description: string | null;
    totalSales: number;
  };

type ExternalItemProps = {
  world: WorldOverview | null;
  moment: ApiMomentFragment | null;
};

type ShowcaseEditionProps = { edition: ShowcaseEditionType } & (
  | { context: 'WORLD' }
  | ({ context: 'EXTERNAL' } & ExternalItemProps)
);

type ShowcaseDropProps = { drop: ShowcaseDropType; sale: Drop['sale'] } & (
  | { context: 'WORLD' }
  | ({ context: 'EXTERNAL' } & ExternalItemProps)
);

type ShowcaseNftProps = { nft: MarketNft<'ALL_MEDIA'> } & (
  | { context: 'WORLD' }
  | ({ context: 'EXTERNAL' } & ExternalItemProps)
);

type ShowcaseMomentProps = {
  moment: ApiMomentFragment;
  momentStats?: MomentStatsType;
  contributors: UserLight[];
  contributorsCount: number;
  isLoading?: boolean;
} & (
  | { context: 'WORLD' }
  | ({ context: 'EXTERNAL' } & {
      world: WorldOverview;
      moment: ApiMomentFragment;
    })
);

interface ScaffoldingProps {
  frame: ReactNode;
  summary: ReactNode;
  market: ReactNode;
}

function Scaffolding(props: ScaffoldingProps) {
  return (
    <ShowcaseContainer>
      {props.frame}
      <Flex
        css={{
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '$4',
        }}
      >
        {props.summary}
        {props.market}
      </Flex>
    </ShowcaseContainer>
  );
}

type SummaryScaffoldingProps = {
  creator: UserMicro;
  description: string | null;
  href: string;
  name: string;
  type: SellableItemType;
  tag: ReactNode;
};

function SummaryScaffolding(props: SummaryScaffoldingProps) {
  const { type } = props;
  const analytics = useAnalytics();

  return (
    <Flex
      css={{
        flexDirection: 'column',
        gap: '$1',
        paddingTop: '$2',

        '@bp1': {
          gap: '$2',
          paddingTop: '$4',
        },

        '@bp3': {
          gap: '$3',
          paddingTop: '$6',
        },
      }}
    >
      <ShowcaseBadge type={props.type} />
      <NextLink href={props.href} passHref>
        <ShowcaseItemHeading
          as="a"
          onClick={() => {
            analytics.track({
              name: 'clicked_showcase_link',
              area: 'name',
              type,
            });
          }}
        >
          {props.name}
        </ShowcaseItemHeading>
      </NextLink>
      <Flex>
        <ShowcaseUserTag nameVariant="prefer-username" user={props.creator} />
      </Flex>
      {props.description && (
        <ShowcaseDescription
          description={props.description}
          href={props.href}
          onSeeDetailsClick={() => {
            analytics.track({
              name: 'clicked_showcase_link',
              area: 'see_details',
              type,
            });
          }}
        />
      )}
      {props.tag && <TagContainer>{props.tag}</TagContainer>}
    </Flex>
  );
}

function ShowcaseSkeleton() {
  return (
    <Scaffolding
      frame={
        <ShowcaseFrame.Root controlAspectRatioVia="container"></ShowcaseFrame.Root>
      }
      summary={
        <>
          <Box>
            <Skeleton.Block
              css={{
                width: 120,
                height: 20,
                '@bp0': {
                  width: 300,
                  height: 50,
                },
              }}
            />
            <Flex
              css={{
                paddingTop: '$3',
                gap: '$2',
                alignItems: 'center',
                '@bp0': {
                  paddingTop: '$4',
                },
              }}
            >
              <Skeleton.Avatar
                size={{
                  '@initial': 0,
                  '@bp1': 3,
                }}
                opaque
              />
              <Skeleton.Block
                css={{
                  width: 60,
                  height: 18,
                  borderRadius: '$4',
                  '@bp0': {
                    width: 120,
                    height: 20,
                  },
                }}
                opaque
              />
            </Flex>
          </Box>
          <Skeleton.Button
            variant="fill"
            css={{
              '@bp1-max': {
                display: 'none',
              },
            }}
          />
        </>
      }
      market={null}
    />
  );
}

function ShowcaseTimedEdition(
  props: ShowcaseEditionProps & { sale: TimedEditionCollectionSale }
) {
  const { edition, sale } = props;
  const href = getPath.collection.page(edition.contractAddress);

  const media =
    edition.assetUrl && edition.mimeType
      ? mapToLaunchMediaAsset(
          { assetUrl: edition.assetUrl, mimeType: edition.mimeType },
          { imageOptions: SHOWCASE_MEDIA_OPTIONS }
        )
      : null;

  const trackEditionMediaClick = useTrackShowcaseMediaClick('edition');

  const getTag = () => {
    if (props.context === 'WORLD') return null;

    if (props.moment && props.world) {
      return <MomentTag world={props.world} moment={props.moment} />;
    } else if (props.world) {
      return <WorldTag world={props.world} />;
    } else {
      return null;
    }
  };

  return (
    <Scaffolding
      frame={
        <ShowcaseFrame.Root controlAspectRatioVia="media">
          <NextLink href={href} passHref>
            <Link onClick={trackEditionMediaClick}>
              {media ? (
                <ShowcaseFrame.MediaContainer>
                  <MediaStack
                    color="$black5-solid"
                    stackCount={2}
                    media={media}
                  />
                </ShowcaseFrame.MediaContainer>
              ) : (
                <UnsupportedItem />
              )}
            </Link>
          </NextLink>
        </ShowcaseFrame.Root>
      }
      summary={
        <SummaryScaffolding
          creator={edition.creator}
          description={edition.description}
          href={href}
          name={edition.name}
          type="edition"
          tag={getTag()}
        />
      }
      market={
        <ShowcaseMarket.TimedEdition
          chainId={edition.chainId}
          contractAddress={edition.contractAddress}
          href={href}
          media={media}
          name={edition.name}
          nftCount={edition.nftCount}
          sale={sale}
          slug={edition.slug}
          totalSales={edition.totalSales}
        />
      }
    />
  );
}

function ShowcaseLimitedEdition(
  props: ShowcaseEditionProps & { sale: LimitedEditionCollectionSale }
) {
  const { edition, sale } = props;
  const href = getPath.collection.page(edition.contractAddress);

  const media =
    edition.assetUrl && edition.mimeType
      ? mapToLaunchMediaAsset(
          { assetUrl: edition.assetUrl, mimeType: edition.mimeType },
          { imageOptions: SHOWCASE_MEDIA_OPTIONS }
        )
      : null;

  const trackEditionMediaClick = useTrackShowcaseMediaClick('edition');

  const getTag = () => {
    if (props.context === 'WORLD') return null;

    if (props.moment && props.world) {
      return <MomentTag world={props.world} moment={props.moment} />;
    } else if (props.world) {
      return <WorldTag world={props.world} />;
    } else {
      return null;
    }
  };

  return (
    <Scaffolding
      frame={
        <ShowcaseFrame.Root controlAspectRatioVia="media">
          <NextLink href={href} passHref>
            <Link onClick={trackEditionMediaClick}>
              {media ? (
                <ShowcaseFrame.MediaContainer>
                  <MediaStack
                    color="$black5-solid"
                    stackCount={2}
                    media={media}
                  />
                </ShowcaseFrame.MediaContainer>
              ) : (
                <UnsupportedItem />
              )}
            </Link>
          </NextLink>
        </ShowcaseFrame.Root>
      }
      summary={
        <SummaryScaffolding
          creator={edition.creator}
          description={edition.description}
          href={href}
          name={edition.name}
          type="edition"
          tag={getTag()}
        />
      }
      market={
        <ShowcaseMarket.LimitedEdition
          chainId={edition.chainId}
          href={href}
          nftCount={edition.nftCount}
          media={media}
          slug={edition.slug}
          contractAddress={edition.contractAddress}
          name={edition.name}
          sale={sale}
          totalSales={edition.totalSales}
        />
      }
    />
  );
}

function ShowcaseDrop(props: ShowcaseDropProps) {
  const { drop, sale } = props;
  const href = getPath.collection.page(drop.contractAddress);

  const getStackMedia = (): LaunchMediaAsset | null => {
    const { media } = props.drop;
    return mapApiMediaToLaunchMedia(media, {
      imageOptions: SHOWCASE_MEDIA_OPTIONS,
    });
  };

  const media = getStackMedia();

  const trackDropMediaClick = useTrackShowcaseMediaClick('drop');

  const getTag = () => {
    if (props.context === 'WORLD') return null;

    if (props.moment && props.world) {
      return <MomentTag world={props.world} moment={props.moment} />;
    } else if (props.world) {
      return <WorldTag world={props.world} />;
    } else {
      return null;
    }
  };

  return (
    <Scaffolding
      frame={
        <ShowcaseFrame.Root controlAspectRatioVia="container">
          <NextLink href={href} passHref>
            <Link onClick={trackDropMediaClick}>
              {media ? (
                <ShowcaseFrame.MediaContainer>
                  <MediaStackHorizontal count={3} media={media} />
                </ShowcaseFrame.MediaContainer>
              ) : (
                <UnsupportedItem />
              )}
            </Link>
          </NextLink>
        </ShowcaseFrame.Root>
      }
      summary={
        <SummaryScaffolding
          creator={drop.creator}
          description={drop.description}
          href={href}
          name={drop.name}
          type="drop"
          tag={getTag()}
        />
      }
      market={
        <ShowcaseMarket.Drop
          chainId={drop.chainId}
          href={href}
          nftCount={drop.nftCount}
          mintPrice={drop.mintPrice}
          generalAvailabilityStartTime={drop.generalAvailabilityStartTime}
          earlyAccessStartTime={drop.earlyAccessStartTime}
          sale={sale}
          status={drop.status}
          contractAddress={drop.contractAddress}
          name={drop.name}
          slug={drop.slug}
          totalSales={drop.totalSales}
          clearingPrice={drop.clearingPrice}
          saleType={drop.saleType}
          media={drop.media}
        />
      }
    />
  );
}

function ShowcaseNft(props: ShowcaseNftProps) {
  const { nft } = props;
  const href = getPath.nft.page({
    usernameOrAddress: nft.creator.username || nft.creator.publicKey,
    collectionSlugOrAddress: nft.contractAddress,
    tokenId: nft.tokenId,
  });

  const trackNftMediaClick = useTrackShowcaseMediaClick('nft');

  const getTag = () => {
    if (props.context === 'WORLD') return null;

    if (props.moment && props.world) {
      return <MomentTag world={props.world} moment={props.moment} />;
    } else if (props.world) {
      return <WorldTag world={props.world} />;
    } else {
      return null;
    }
  };

  const getMedia = () => {
    if (nft.media) {
      /* Disable linking for interactive 3D model viewer */
      if (nft.media.type === 'model') {
        return <ShowcaseMedia media={nft.media} />;
      }
      return (
        <NextLink href={href} passHref>
          <Link onClick={trackNftMediaClick}>
            <ShowcaseMedia media={nft.media} />
          </Link>
        </NextLink>
      );
    }
    return (
      <NextLink href={href} passHref>
        <Link onClick={trackNftMediaClick}>
          <UnsupportedItem />
        </Link>
      </NextLink>
    );
  };
  return (
    <Scaffolding
      frame={
        <ShowcaseFrame.Root controlAspectRatioVia="media">
          {getMedia()}
        </ShowcaseFrame.Root>
      }
      summary={
        <SummaryScaffolding
          creator={nft.creator}
          description={nft.description || null}
          href={href}
          name={nft.name ?? ''}
          type="nft"
          tag={getTag()}
        />
      }
      market={
        <ShowcaseMarket.Nft
          href={href}
          nft={{ ...nft, media: mapShowcaseMediaToPreviewMedia(nft.media) }}
        />
      }
    />
  );
}

function ShowcaseMoment(props: ShowcaseMomentProps) {
  const {
    moment,
    contributors,
    contributorsCount,
    momentStats,
    context,
    isLoading,
  } = props;

  const optimizedAsset = optimizeAsset(moment.posterUrl, {
    w: 1080,
    fit: 'crop',
    auto: undefined,
    dpr: 2,
  });

  const worldQuery = useWorldSlugById(
    {
      id: moment.worldId,
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const auth = useAuth();

  const momentBookmarksQuery = useMomentBookmarks(
    {
      publicKey: hasPublicKey(auth) ? auth.publicKey : '',
      perPage: 100,
      page: 0,
    },
    { enabled: hasPublicKey(auth) }
  );

  const getBookmarkId = (): string | null => {
    if (momentBookmarksQuery.data) {
      const momentBookmark =
        momentBookmarksQuery.data.momentBookmarks.items.find(
          (item) => item.content.id === moment.id
        );

      return momentBookmark ? momentBookmark.id : null;
    } else {
      return null;
    }
  };

  const href = getPath.moment.page({
    // worldId is used as a fallback to prevent invalid href's while worldQuery is loading
    worldSlug: worldQuery.data?.world?.slug || String(moment.worldId),
    momentId: moment.id,
  });
  const momentStatus = momentStats
    ? getMomentStatus({
        startsAt: moment.startsAt,
        liveActivityCount: momentStats.liveActivityCount,
        isSoldOut: momentStats.isSoldOut,
      })
    : null;

  const analytics = useAnalytics();
  const modal = useModal();

  const getMomentStats = () => {
    if (!momentStats || momentStats.collectorsCount === 0) return null;
    return <MomentStats stats={momentStats} />;
  };

  return (
    <Scaffolding
      frame={
        <ShowcaseFrame.Root controlAspectRatioVia="media">
          <NextLink href={href} passHref>
            <Link>
              <ShowcaseFrame.MediaContainer>
                <MediaStack
                  color="$black5-solid"
                  stackCount={0}
                  media={{
                    type: 'image',
                    src: optimizedAsset,
                  }}
                />
              </ShowcaseFrame.MediaContainer>
            </Link>
          </NextLink>
        </ShowcaseFrame.Root>
      }
      summary={
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '$3',
            alignItems: 'center',
            '@bp0': {
              gap: '$5',
            },
          }}
        >
          {context === 'EXTERNAL' && props.world && (
            <Box css={{ display: 'flex', alignItems: 'center', gap: '$2' }}>
              <Text as="span" color="dim" size={1}>
                Presented by{' '}
              </Text>
              <WorldTag.Mini world={props.world} size={1} />
            </Box>
          )}
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              gap: '$1',
            }}
          >
            <NextLink href={href} passHref>
              <ShowcaseItemHeading
                as="a"
                onClick={() => {
                  analytics.track({
                    name: 'clicked_showcase_link',
                    area: 'name',
                    type: 'moment',
                  });
                }}
              >
                {moment.name}
              </ShowcaseItemHeading>
            </NextLink>
            {isLoading ? (
              <MomentStats.Skeleton />
            ) : (
              momentStatus && (
                <MomentDynamicLabel
                  startsAt={moment.startsAt}
                  momentStatus={momentStatus}
                  size={{ '@initial': 3, '@bp1': 5 }}
                />
              )
            )}
          </Box>
          {getMomentStats()}
          {contributorsCount > 0 && (
            <Text
              color="dim"
              size={1}
              lineHeight={2}
              css={{
                '& div': {
                  display: 'inline',
                },
              }}
            >
              Works by{' '}
              <Contributors
                contributors={contributors}
                contributorsCount={contributorsCount}
                onMoreClick={() => {
                  modal.setModal({
                    type: 'MOMENT_CREATORS',
                    momentId: moment.id,
                  });
                }}
              />
            </Text>
          )}
        </Box>
      }
      market={
        <ShowcaseMarket.Moment
          href={href}
          moment={moment}
          bookmarkId={getBookmarkId()}
        />
      }
    />
  );
}

const useTrackShowcaseMediaClick = (type: SellableItemType) => {
  const analytics = useAnalytics();

  return useCallback(function trackShowcaseMediaClick() {
    analytics.track({
      name: 'clicked_showcase_link',
      type,
      area: 'media',
    });
  }, []);
};

const IconWrapper = styled(ArtworkUnsupportedMessageWrapper, {
  width: '56px',
  height: '56px',
  transition: 'opacity $1',
  marginY: 'auto',
});

function UnsupportedItem() {
  return (
    <Flex
      center
      css={{ width: '100%', height: '100%' }}
      style={createCanvasBackground({
        hasTransparentBackground: true,
        layering: 'darken',
      })}
    >
      <IconWrapper
        aria-label={getNoAssetArtworkLabel()}
        backgroundColor="light"
      >
        <AssetUnsupportedIcon />
      </IconWrapper>
    </Flex>
  );
}
const TagContainer = styled('div', {
  borderTop: 'solid 1px $black5',
  borderBottom: 'solid 1px $black5',
  marginTop: '$3',
  paddingY: '$3',

  '@bp1': {
    borderBottom: 'none',
    marginTop: '$1',
    paddingBottom: '$0',
    paddingTop: '$6',
  },
});

const ShowcaseItemHeading = styled(H2Heading, {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  lineHeight: 1.1,
  color: 'inherit',
  textDecoration: 'none',
  '@bp1-max': {
    WebkitLineClamp: 1,
  },
});

ShowcaseItemHeading.defaultProps = {
  size: {
    '@initial': 4,
    '@bp1': 5,
    '@bp3': 6,
  },
  weight: 'medium',
};

const ShowcaseUserTag = styled(UserTag, {});
ShowcaseUserTag.defaultProps = {
  size: {
    '@initial': 0,
    '@bp1': 3,
  },
  type: 'avatar-text',
};

type ShowcaseMomentConnectedProps = {
  moment: ApiMomentFragment;
} & (
  | { context: 'WORLD' }
  | ({ context: 'EXTERNAL' } & {
      world: WorldOverview;
      moment: ApiMomentFragment;
    })
);

function ShowcaseMomentConnected(props: ShowcaseMomentConnectedProps) {
  const creatorsQuery = useMomentCreators({
    momentId: props.moment.id,
  });

  const momentStatsQuery = useMomentStats({
    momentId: props.moment.id,
  });

  const momentStats = momentStatsQuery.data?.momentStats;

  const contributors = creatorsQuery.data
    ? creatorsQuery.data.momentCreators.items.slice(0, 5)
    : [];

  const contributorsCount = creatorsQuery.data
    ? creatorsQuery.data.momentCreators.totalItems
    : 0;

  if (props.context === 'EXTERNAL') {
    return (
      <ShowcaseMoment
        moment={props.moment}
        world={props.world}
        context={props.context}
        contributors={contributors}
        contributorsCount={contributorsCount}
        momentStats={momentStats}
        isLoading={momentStatsQuery.isLoading}
      />
    );
  }

  return (
    <ShowcaseMoment
      moment={props.moment}
      context={props.context}
      contributors={contributors}
      contributorsCount={contributorsCount}
      momentStats={momentStats}
      isLoading={momentStatsQuery.isLoading}
    />
  );
}

const ShowcaseItem = {
  TimedEdition: ShowcaseTimedEdition,
  LimitedEdition: ShowcaseLimitedEdition,
  Drop: ShowcaseDrop,
  Moment: ShowcaseMoment,
  MomentConnected: ShowcaseMomentConnected,
  Nft: ShowcaseNft,
  Skeleton: ShowcaseSkeleton,
};

export default ShowcaseItem;
